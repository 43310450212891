import React from "react"
import Calendar from "../Calendar/Calendar"

export default function Home() {
  return (
    <div>
      <div
        style={{
          padding: "20px",
          borderRadius: "5px",
          marginBottom: "20px",
          textAlign: "left",
          fontSize: "18px",
          fontFamily: "Arial, Helvetica, sans-serif",
          maxWidth: "50%",
          margin: "0 auto",
        }}
      >
MEMBERSHIP: Broadway Maven Membership is just $18 a month and includes 5-15 classes and other expert-led Broadway experiences, plus a password to the Clips Archive and periodic giveaways to Broadway and off-Broadway shows, including at least one opening-night performance and party. Upcoming Members-only classes are listed on the calendar below. Join this vibrant learning community below. It’s your home for Broadway appreciation!
      </div>
     
     

      <a
        href="https://thebroadwaymaven.substack.com/subscribe"
        target="_blank"
        rel="noreferrer"
        style={{
          display: "block",
          fontSize: "24px",
          textDecoration: "underline",
          hover: "hand",
          color: "#7756e3",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Become a Member
      </a>
      {/* <Link to="/all-access">
              <div
                style={{
                  fontSize: "24px",
                  textDecoration: "underline",
                  hover: "hand",
                  color: "#7756e3",
                }}
              >Broadway Maven ALL-ACCESS Pass</div>
            </Link>

            <br />
            {/* <Link to="/studentseminar">
              <div
                style={{
                  fontSize: "24px",
                  textDecoration: "underline",
                  hover: "hand",
                  color: "#7756e3",
                }}
              >Apply for the Student Seminar</div>
            </Link> */}
      <br />
    

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Calendar />
      </div>
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <iframe
          title="David Benkof Video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/YxaYYq3hJ64?si=Bjg1ZoVvGPg38JVi?autoplay=1"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <br />

      <div
        style={{
          padding: "20px",
          borderRadius: "5px",
          marginBottom: "20px",
          textAlign: "left",
          fontSize: "18px",
          fontFamily: "Arial, Helvetica, sans-serif",
          maxWidth: "50%",
          margin: "0 auto",
        }}
      >
        The Broadway Maven is an educational community that helps its members think more deeply about musical theater. Every month, members may attend 5-15 expert-led classes and innovative Broadway experiences, all for just $18. We also foster enthusiasm for Broadway through the FREE weekly substack newsletter <a href="https://thebroadwaymaven.substack.com/" target="_blank" rel="noreferrer" style={{ color: "#007bff", textDecoration: "none" }}> <em>MARQUEE</em></a> and host an expansive <a href="https://www.youtube.com/c/DavidBenkofTheBroadwayMaven" target="_blank" rel="noreferrer" style={{ color: "#007bff", textDecoration: "none" }}>YouTube channel</a>. It's your home for Broadway appreciation.
      </div>
      <br />
    </div>
  )
}
